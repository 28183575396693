@import 'colors';

:root {
  --border-radius-default: 8px;
  --box-shadow-default: 0 0 0 1px rgba(63, 63, 68, 0.05),
    0 1px 3px 0 rgba(63, 63, 68, 0.15);
  --box-shadow-shadow: 0 0 0 1px rgba(6, 44, 82, 0.1),
    0 2px 16px 0 rgba(33, 43, 54, 0.08);
  --box-shadow-subdued: 0 0 0 1px rgba(63, 63, 68, 0.07),
    0 1px 3px 0 rgba(63, 63, 68, 0.12);

  --spacing-base: 0.5rem;
  --spacing-tiny: var(--spacing-base);
  --spacing-medium: calc(var(--spacing-base) * 1.5);
  --spacing-small: calc(var(--spacing-base) * 2);
  --spacing-default: calc(var(--spacing-base) * 3);
  --spacing-large: calc(var(--spacing-base) * 4);

  --breakpoint-phone: 320px;
  --breakpoint-tablet: 768px;
  --breakpoint-desktop: 1024px;
  --breakpoint-wide: 1400px;

  --transition-timing-default: cubic-bezier(1, 0, 0.24, 0.77);

  --default-border: 1px solid #ccc;
}

.no-margin {
  margin: 0;
}