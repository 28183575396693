$animation-duration: 0.4s;

@mixin label-inactive() {
  font-size: 1.2rem;
  transform: translateY(0);
}

@mixin label-active() {
  font-size: 0.75rem;
  transform: translateY(-12px);
}

.form-field {
  display: block;
  text-align: left;
}
.error-message {
  color: var(--color-red-900);
}

.form-field__label {
  @include label-active();
  display: block;
  font-weight: normal;
  left: 0;
  margin: 0;
  padding: 12px 12px 0;
  position: absolute;
  top: 0;
  transition: all $animation-duration;
  width: 100%;
}

.form-field__control {
  background: var(--white);
  border-radius: 8px;
  border: 1px solid var(--green-100);
  overflow: hidden;
  position: relative;
  width: 100%;

  &.error {
    border-color: var(--color-red-900);
  }

  .mat-form-field {
    padding: var(--spacing-medium);
    .mat-form-field-label {
      @include label-active();
      display: block;
      font-weight: normal;
      left: 0;
      margin: 0;
      padding: 12px 12px 0;
      position: absolute;
      top: 0;
      transition: all $animation-duration;
      width: 100%;
    }
  }
}

.form-field__input,
.form-field__textarea {
  appearance: none;
  background: transparent;
  border: 0;
  color: var(--grey-900);
  display: block;
  font-size: 1.2rem;
  margin-top: 24px;
  outline: 0;
  padding: 0 12px 10px 12px;
  width: 100%;

  .icon {
    &.remove {
      fill: red;
    }
  }

  // IE 10-11
  &:-ms-input-placeholder {
    ~ .form-field__label {
      @include label-inactive();
    }
  }
  // All other browsers except Edge
  &:placeholder-shown {
    ~ .form-field__label {
      @include label-inactive();
    }
  }
  &:focus {
    ~ .form-field__label {
      color: var(--color-green-500);
      @include label-active();
    }

    ~ .form-field__control {
      border: 1px solid var(--green-200);
    }
  }

  &.small {
    margin-top: 18px;
    font-size: 1rem;
  }
}
.form-field__textarea {
  height: 150px;
}
