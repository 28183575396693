.box {
  background: #fff;
  // box-shadow: var(--box-shadow-default);
  border: 1px solid #ccc;
  padding: var(--spacing-default);
  overflow: hidden;
  max-width: 100%;
  &.no-padding {
    padding: 0;
    .box-section {
      padding: var(--spacing-default);
    }
  }
  // + .box {
  //   margin-top: var(--spacing-default);
  // }
  &.overflow {
    overflow: visible;
  }
  &.no-shadow {
    box-shadow: none;
  }
}
