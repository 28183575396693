@import 'breakpoints';

* {
  box-sizing: border-box;
}

:root {
  overflow-x: hidden;
  height: 100%;
}

body {
  min-height: 100%;
  display: flex;
  flex-direction: column;
}

.app-root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background: var(--bg-body);
}

.main {
  margin: 0;
  padding: 0;
}

.cursor {
  cursor: pointer;
}

a {
  color: inherit;
  text-decoration: inherit;
  cursor: pointer;
  &.link-stealth:hover {
    border-bottom: 1px solid currentColor;
  }
}

a.link {
  color: var(--color-primary);
  text-decoration: none;
  font-weight: 600;
  cursor: pointer;
  border-bottom: 1px solid transparent;
  line-height: 1.4;
  &:hover {
    border-color: currentColor;
  }
}

a.link-alt {
  text-decoration: none;
  cursor: pointer;
  border-bottom: 1px solid currentColor;
  line-height: 1.4;
  &:hover {
    color: var(--color-primary);
  }
}

hr {
  height: 1px;
  display: block;
  margin: var(--spacing-small) 0;
  background: var(--color-grey-300);
  border: none;
  padding: 0;
}

.divider-large {
  margin: var(--spacing-default) 0;
}

::-moz-focus-inner {
  outline: none;
  border: none;
}

:focus {
  outline: none;
}
::-moz-focus-inner {
  border: 0;
}

::-webkit-scrollbar {
  width: 10px;
}
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
}
::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background-color: var(--color-grey-500);
}

body:not(.page-signup) .grecaptcha-badge {
  opacity: 0 !important;
  visibility: hidden !important;
}

address {
  display: block;
  font-style: normal;
}

.home-container {
  max-width: 1388px;
  margin: 0 56px;
  @include media('>wide') {
    margin: 0 auto;
  }
  @include media('<tablet') {
    margin: 0 var(--spacing-default);
  }
}

.green-bg {
  background-color: var(--green-50);
  width: 100vw;
}

.white-bg {
  background-color: var(--white);
}

.gree-bg-dark {
  background-color: var(--green-800);
  width: 100vw;
  color: var(--white);
}

.privacy-container {
  mat-tab-header {
    border: none;
  }
}

// Material

.full-width-table {
  width: 100%;
}
