@import 'breakpoints';

// Baton turbo
@font-face {
  font-family: 'Baton Turbo';
  src: url('/assets/fonts/woff2/BatonTurbo-Regular.otf') format('otf');
  font-weight: normal;
}
@font-face {
  font-family: 'Baton Turbo';
  src: url('/assets/fonts/woff2/BatonTurbo-Medium.otf') format('otf');
  font-weight: 500;
}
@font-face {
  font-family: 'Baton Turbo';
  src: url('/assets/fonts/woff2/BatonTurbo-Book.otf') format('otf');
  font-weight: 600;
}

@font-face {
  font-family: 'Ivar Headline';
  src: url('/assets/fonts/IvarHeadline-Regular.otf') format('otf');
}

// Ivar headline
:root {
  --font-headlines: 'Baton Turbo';
  --font-body: 'Baton Turbo';
  --font-display: 'Ivar Headline', serif;
  --font-family-headlines: var(--font-headlines), sans-serif;
  --font-family-body: var(--font-body), sans-serif;
  --font-size-base: 16px;

  --font-size-caption: 0.875em;
  --font-size-tiny: 0.75em;
  --font-size-body-1: 1em;
  --font-size-body-2: 1.25em;
  --font-size-subheading: 1.125em;
  --font-size-headline: 1.5em;
  --font-size-display-1: 2.125em;
  --font-size-display-2: 2.8125em;
  --font-size-display-3: 3.5em;
  --font-size-display-4: 7em;

  --font-color-headlines: rgb(75, 75, 75);
  --font-color-body: var(--grey-900);

  --line-height-base: 1.5;
  --line-height-headlines: 1.25;
}

:root {
  font-size: var(--font-size-base);
}

body {
  font-family: var(--font-family-body);
  color: var(--font-color-body);
  line-height: var(--line-height-base);
  -webkit-font-smoothing: antialiased;
  text-rendering: geometricprecision;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: var(--spacing-default);
  // color: var(--font-color-headlines);

  &.display {
    font-family: var(--font-display);
  }
}

.text-body-1 {
  font-size: var(--font-size-body-1);
  font-weight: 400;
}
.text-body-2 {
  font-size: var(--font-size-body-2);
  line-height: 130%;
  font-weight: normal;

  @include media('<wide') {
    font-size: var(--font-size-body-1);
  }
}

.medium {
  font-weight: 500;
}

.bold {
  font-weight: 600;
}

.tiny {
  font-size: var(--font-size-tiny);
}

.text-caption {
  font-size: var(--font-size-caption);
}
.text-subheading {
  font-size: var(--font-size-subheading);
  @include media('<wide') {
    font-size: var(--font-size-body-1);
  }
}

.font-green {
  color: var(--color-primary);
}

.font-yellow {
  color: var(--color-warning);
}

.font-red {
  color: var(--color-error);
}

.text-title,
h2 {
  font-size: var(--font-size-display-1);
  font-weight: 600;
  line-height: var(--line-height-headlines);

  &.display {
    font-family: var(--font-display);
    font-size: var(--font-size-display-3);
    font-weight: 400;

    @include media('<wide') {
      font-size: var(--font-size-display-2);
    }
  }
}
.text-headline {
  font-size: var(--font-size-headline);
  font-weight: 600;
  line-height: var(--line-height-headlines);
}

.large-font {
  font-size: var(--font-size-headline);
  font-weight: 400;
  line-height: 1.2;
}

h1 {
  &.display {
    font-family: var(--font-display);
    font-size: 6em;
    font-weight: 300;
    line-height: 0.9;
    letter-spacing: 0;
  }
}

// ** DISPLAY TEXT **//

.text-display-1 {
  font-size: var(--font-size-display-1);
  font-weight: 400;
  line-height: 120%;

  @include media('<wide') {
    font-size: var(--font-size-headline);
  }

  @include media('<desktop') {
    font-size: var(--font-size-subheading);
  }
}

.text-display-2 {
  font-size: var(--font-size-display-2);
  font-weight: 600;
  line-height: var(--line-height-headlines);
}
.text-display-3 {
  font-size: var(--font-size-display-3);
  font-weight: 600;
  line-height: var(--line-height-headlines);
}
.text-display-4 {
  font-size: var(--font-size-display-4);
  font-weight: 600;
  line-height: var(--line-height-headlines);
}

.text-headline-2 {
  font-weight: 600;
  text-transform: uppercase;
  font-size: 0.9375rem;
  line-height: 1.188rem;
  letter-spacing: 0.54px;
}

.headline-divider {
  border-bottom: 1px solid var(--color-grey-300);
  padding-bottom: var(--spacing-base);
}

.text-subdued {
  color: var(--color-subdued);
}

.text-highlighted {
  color: var(--font-color-headlines);
}

.sidebar-headline {
  margin-bottom: var(--spacing-small);
}

.sidebar-subtitle {
  margin-top: var(--spacing-base);
}

.title-wrapper {
  margin-bottom: var(--spacing-large);
  h1 {
    margin-bottom: 4px;
  }
}

a {
  text-decoration: none;
}

.align-center {
  text-align: center;
}
