@import 'breakpoints';

.card-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  grid-template-rows: auto;
  column-gap: var(--spacing-default);
  row-gap: var(--spacing-default);
}

.detailed-grid {
  display: grid;
  grid-template-areas:
    'history '
    'analysis';
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  gap: var(--spacing-default);
  margin: var(--spacing-default) 0;

  .history {
    grid-area: history;
  }

  .comments {
    grid-area: comments;
  }

  .analysis {
    grid-area: analysis;
  }
}

.analysis-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: var(--spacing-large);
  grid-template-areas: 'content sidebar';

  .content {
    // max-width: calc(100vw - 300px - 640px);
    grid-area: content;

    img {
      max-width: 100%;
    }
  }

  .sidebar {
    margin: var(--spacing-default) 0;
    h3 {
      margin-bottom: var(--spacing-tiny);
    }

    hr {
      margin: var(--spacing-tiny) 0;
    }
    .graph-grid {
      grid-area: sidebar;
      display: grid;
      grid-template-columns: 1fr 1fr;
      // grid-template-rows: 1fr 1fr;
      gap: var(--spacing-default);
      img {
        max-width: 100%;
      }
    }
  }

  @include media('<tablet') {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto;

    .graph-grid {
      grid-template-columns: 1fr;
      grid-template-rows: auto;
    }
  }
}

.single-analysis-grid {
  margin-top: var(--spacing-default);
  display: grid;
  gap: var(--spacing-default);
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr;
  grid-template-areas: 'video video comment comment';

  .video {
    grid-area: video;
  }

  .comment {
    grid-area: comment;
  }

  .stridechart {
    grid-area: stridechart;
  }

  .head1 {
    grid-area: head1;
  }

  .head2 {
    grid-area: head2;
  }

  .hind1 {
    grid-area: hind1;
  }
  .hind2 {
    grid-area: hind2;
  }
}

.twelve-grid {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  gap: var(--spacing-default);

  &.large-padding {
    padding: calc(var(--spacing-base) * 19.5) 0;
  }

  @include media('<tablet') {
    grid-template-columns: repeat(4, 1fr);

    &.large-padding {
      padding: calc(var(--spacing-base) * 8) 0;
    }
  }
}
