.mat-form-field {
  width: 100%;
}

/* Border */
.mat-form-field-appearance-outline .mat-form-field-outline {
  color: var(--green-100);
}
.mat-form-field-appearance-outline .mat-form-field-outline-thick {
  color: var(--green-300);
}
.mat-form-field-appearance-outline.mat-form-field-can-float-mat-form-field-should-float .mat-form-field-outline-gap {
  border-top-color: var(--green-300);
}
.mat-form-field-appearance-outline.mat-focused {
  color: var(--green-300);
}
.mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
  color: var(--green-300);
}

/* Font color */
.mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-outline-gap {
  border-top-color: var(--green-300);
}

.machine-selector::after {
  position: absolute;
  right: 10px;
  top: 10px;
  content: " ";
  width: 24px;
  height: 24px;
}
.machine-selector.idle::after {
  content: " ";
  -webkit-mask: url("/assets/images/check_circle-24px.svg") no-repeat 50% 50%;
  mask: url("/assets/images/check_circle-24px.svg") no-repeat 50% 50%;
  -webkit-mask-size: cover;
  mask-size: cover;
  background-color: green;
}
.machine-selector.working:after {
  content: " ";
  -webkit-mask: url("/assets/images/info-24px.svg") no-repeat 50% 50%;
  mask: url("/assets/images/info-24px.svg") no-repeat 50% 50%;
  -webkit-mask-size: cover;
  mask-size: cover;
  background-color: var(--accent-yellow-700);
}
.machine-selector.error::after {
  content: " ";
  -webkit-mask: url("/assets/images/info-24px.svg") no-repeat 50% 50%;
  mask: url("/assets/images/info-24px.svg") no-repeat 50% 50%;
  -webkit-mask-size: cover;
  mask-size: cover;
  background-color: var(--red-800);
}

* {
  box-sizing: border-box;
}

:root {
  overflow-x: hidden;
  height: 100%;
}

body {
  min-height: 100%;
  display: flex;
  flex-direction: column;
}

.app-root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background: var(--bg-body);
}

.main {
  margin: 0;
  padding: 0;
}

.cursor {
  cursor: pointer;
}

a {
  color: inherit;
  text-decoration: inherit;
  cursor: pointer;
}
a.link-stealth:hover {
  border-bottom: 1px solid currentColor;
}

a.link {
  color: var(--color-primary);
  text-decoration: none;
  font-weight: 600;
  cursor: pointer;
  border-bottom: 1px solid transparent;
  line-height: 1.4;
}
a.link:hover {
  border-color: currentColor;
}

a.link-alt {
  text-decoration: none;
  cursor: pointer;
  border-bottom: 1px solid currentColor;
  line-height: 1.4;
}
a.link-alt:hover {
  color: var(--color-primary);
}

hr {
  height: 1px;
  display: block;
  margin: var(--spacing-small) 0;
  background: var(--color-grey-300);
  border: none;
  padding: 0;
}

.divider-large {
  margin: var(--spacing-default) 0;
}

::-moz-focus-inner {
  outline: none;
  border: none;
}

:focus {
  outline: none;
}

::-moz-focus-inner {
  border: 0;
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
}

::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background-color: var(--color-grey-500);
}

body:not(.page-signup) .grecaptcha-badge {
  opacity: 0 !important;
  visibility: hidden !important;
}

address {
  display: block;
  font-style: normal;
}

.home-container {
  max-width: 1388px;
  margin: 0 56px;
}
@media (min-width: 1440px) {
  .home-container {
    margin: 0 auto;
  }
}
@media (max-width: 767px) {
  .home-container {
    margin: 0 var(--spacing-default);
  }
}

.green-bg {
  background-color: var(--green-50);
  width: 100vw;
}

.white-bg {
  background-color: var(--white);
}

.gree-bg-dark {
  background-color: var(--green-800);
  width: 100vw;
  color: var(--white);
}

.privacy-container mat-tab-header {
  border: none;
}

.full-width-table {
  width: 100%;
}

:root {
  --color-primary: var(--green-500);
  --color-primary-light: var(--green-50);
  --color-primary-dark: var(--green-700);
  --color-primary-hover: var(--secondary);
  --color-primary-brand: var(--color-indigo-400);
  --color-primary-brand-light: var(--color-indigo-300);
  --color-primary-brand-dark: var(--color-indigo-900);
  --color-primary-brand-hover: var(--color-indigo-600);
  --color-secondary: var(--color-green-500);
  --color-secondary-light: var(--color-green-300);
  --color-secondary-dark: var(--color-green-900);
  --color-secondary-hover: var(--color-green-600);
  --color-success: var(--color-green-500);
  --color-success-light: var(--color-green-300);
  --color-success-dark: var(--color-green-900);
  --color-success-hover: var(--color-green-600);
  --color-error: var(--color-red-600);
  --color-error-light: var(--color-red-300);
  --color-error-dark: var(--color-red-900);
  --color-error-hover: var(--color-red-700);
  --color-warning: var(--color-yellow-500);
  --color-warning-light: var(--color-yellow-300);
  --color-warning-dark: var(--color-yellow-900);
  --color-warning-hover: var(--color-yellow-600);
  --color-subdued: #a7b1bf;
  --green-50: #ebf5f4;
  --green-100: #c2dad7;
  --green-200: #91bab5;
  --green-300: #59958e;
  --green-400: #297269;
  --green-500: #03594f;
  --green-600: #034a41;
  --green-700: #024139;
  --green-800: #02312c;
  --green-900: #012823;
  --accent-mint-100: #f6fefc;
  --accent-mint-50: #f0fdf9;
  --accent-mint-100: #c5f7ee;
  --accent-mint-200: #98f0e0;
  --accent-mint-300: #6cead3;
  --accent-mint-400: #2ed3b7;
  --accent-mint-500: #15b79e;
  --accent-mint-600: #0e9384;
  --accent-mint-700: #3fe4c5;
  --warn-yellow-25: #fefdf0;
  --warn-yellow-50: #fefbe8;
  --warn-yellow-100: #fef7c3;
  --warn-yellow-200: #feee95;
  --warn-yellow-300: #fde272;
  --warn-yellow-400: #fac515;
  --warn-yellow-500: #eaaa08;
  --warn-yellow-600: #ca8504;
  --warn-yellow-700: #a15c07;
  --warn-yellow-800: #854a0e;
  --warn-yellow-900: #713b12;
  --error-red-25: #fffbfa;
  --error-red-50: #fef3f2;
  --error-red-100: #fee4e2;
  --error-red-200: #fecdca;
  --error-red-300: #fda29b;
  --error-red-400: #f97066;
  --error-red-500: #f04438;
  --error-red-600: #d92d20;
  --error-red-700: #b42318;
  --error-red-800: #912018;
  --error-red-900: #7a271a;
  --success-green-25: #f6fef9;
  --success-green-50: #ecfdf3;
  --success-green-100: #d1fadf;
  --success-green-200: #a6f4c5;
  --success-green-300: #6ce9a6;
  --success-green-400: #32d583;
  --success-green-500: #12b76a;
  --success-green-600: #039855;
  --success-green-700: #027a48;
  --success-green-800: #05603a;
  --success-green-900: #054f31;
  --accent-yellow-100: #fffdcc;
  --accent-yellow-200: #fffc99;
  --accent-yellow-400: #fffa66;
  --accent-yellow-700: #fff500;
  --grey-50: #fafafa;
  --grey-100: #f5f5f5;
  --grey-200: #eeeeee;
  --grey-300: #e0e0e0;
  --grey-400: #bdbdbd;
  --grey-500: #9e9e9e;
  --grey-600: #757575;
  --grey-700: #616161;
  --grey-800: #424242;
  --grey-900: #212121;
  --red-50: #ffebee;
  --red-100: #ffcdd2;
  --red-200: #ef9a9a;
  --red-300: #e57373;
  --red-400: #ef5350;
  --red-500: #f44336;
  --red-600: #e53935;
  --red-700: #d32f2f;
  --red-800: #c62828;
  --red-900: #b71c1c;
  --hind-bar: rgba(58, 104, 127, 0.7);
  --hind-clock1: #4e7a9a;
  --hind-clock2: #6a95ac;
  --hind-clock3: #8ebbcf;
  --hind-clock4: #c4d6d9;
  --head-bar: rgba(102, 80, 126, 0.7);
  --head-clock1: #926d9f;
  --head-clock2: #af98c3;
  --head-clock3: #bebfd6;
  --head-clock4: #d6d6d6;
  --link-gray: #9e9ea6;
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #007bff;
  --secondary: #6c757d;
  --success: #28a745;
  --info: #17a2b8;
  --warning: #ffc107;
  --danger: #dc3545;
  --light: #f8f9fa;
  --dark: #343a40;
  --bg-body: #f4f4f4;
  --color-red-50: #fceeea;
  --color-red-100: #f8d4cb;
  --color-red-200: #f3b7a8;
  --color-red-300: #ee9a85;
  --color-red-400: #eb856b;
  --color-red-500: #e76f51;
  --color-red-600: #e4674a;
  --color-red-700: #e05c40;
  --color-red-800: #dd5237;
  --color-red-900: #d74027;
  --color-pink-50: #fbf1fc;
  --color-pink-100: #f2d0f3;
  --color-pink-200: #edc0ef;
  --color-pink-300: #e9aeeb;
  --color-pink-400: #e49fe7;
  --color-pink-500: #e295e5;
  --color-pink-600: #d587d8;
  --color-pink-700: #c979cc;
  --color-pink-800: #b46bb6;
  --color-pink-900: #a35ba5;
  --color-indigo-50: #e5e9ea;
  --color-indigo-100: #bec8cb;
  --color-indigo-200: #93a3a9;
  --color-indigo-300: #677e87;
  --color-indigo-400: #47626d;
  --color-indigo-500: #264653;
  --color-indigo-600: #223f4c;
  --color-indigo-700: #1c3742;
  --color-indigo-800: #172f39;
  --color-indigo-900: #0d2029;
  --color-blue-50: #ebfaff;
  --color-blue-100: #d8f5ff;
  --color-blue-200: #b1e9fb;
  --color-blue-300: #96e2fa;
  --color-blue-400: #85ddf9;
  --color-blue-500: #3cc8f4;
  --color-blue-600: #2fb1da;
  --color-blue-700: #2d8fc9;
  --color-blue-800: #0e78b6;
  --color-blue-900: #095480;
  --color-green-50: #e5f3f2;
  --color-green-100: #bfe2dd;
  --color-green-200: #95cec7;
  --color-green-300: #6abab1;
  --color-green-400: #4aaca0;
  --color-green-500: #2a9d8f;
  --color-green-600: #259587;
  --color-green-700: #1f8b7c;
  --color-green-800: #198172;
  --color-green-900: #0f6f60;
  --color-yellow-50: #fcf8ed;
  --color-yellow-100: #f8edd2;
  --color-yellow-200: #f4e2b5;
  --color-yellow-300: #f0d697;
  --color-yellow-400: #eccd80;
  --color-yellow-500: #e9c46a;
  --color-yellow-600: #e3b657;
  --color-yellow-700: #f9a825;
  --color-yellow-800: #dfaf4d;
  --color-yellow-900: #d9a23c;
  --color-grey-50: #fafcfd;
  --color-grey-100: #f5f9fc;
  --color-grey-150: #ebf3f9;
  --color-grey-200: #ebeff5;
  --color-grey-300: #dce3ed;
  --color-grey-400: #c6d3e4;
  --color-grey-500: #8191a5;
  --color-grey-600: #6a7c94;
  --color-grey-700: #5a697e;
  --color-grey-800: #353f4c;
  --color-grey-900: #212831;
}

:root {
  --color-primary: var(--green-500);
  --color-primary-light: var(--green-50);
  --color-primary-dark: var(--green-700);
  --color-primary-hover: var(--secondary);
  --color-primary-brand: var(--color-indigo-400);
  --color-primary-brand-light: var(--color-indigo-300);
  --color-primary-brand-dark: var(--color-indigo-900);
  --color-primary-brand-hover: var(--color-indigo-600);
  --color-secondary: var(--color-green-500);
  --color-secondary-light: var(--color-green-300);
  --color-secondary-dark: var(--color-green-900);
  --color-secondary-hover: var(--color-green-600);
  --color-success: var(--color-green-500);
  --color-success-light: var(--color-green-300);
  --color-success-dark: var(--color-green-900);
  --color-success-hover: var(--color-green-600);
  --color-error: var(--color-red-600);
  --color-error-light: var(--color-red-300);
  --color-error-dark: var(--color-red-900);
  --color-error-hover: var(--color-red-700);
  --color-warning: var(--color-yellow-500);
  --color-warning-light: var(--color-yellow-300);
  --color-warning-dark: var(--color-yellow-900);
  --color-warning-hover: var(--color-yellow-600);
  --color-subdued: #a7b1bf;
  --green-50: #ebf5f4;
  --green-100: #c2dad7;
  --green-200: #91bab5;
  --green-300: #59958e;
  --green-400: #297269;
  --green-500: #03594f;
  --green-600: #034a41;
  --green-700: #024139;
  --green-800: #02312c;
  --green-900: #012823;
  --accent-mint-100: #f6fefc;
  --accent-mint-50: #f0fdf9;
  --accent-mint-100: #c5f7ee;
  --accent-mint-200: #98f0e0;
  --accent-mint-300: #6cead3;
  --accent-mint-400: #2ed3b7;
  --accent-mint-500: #15b79e;
  --accent-mint-600: #0e9384;
  --accent-mint-700: #3fe4c5;
  --warn-yellow-25: #fefdf0;
  --warn-yellow-50: #fefbe8;
  --warn-yellow-100: #fef7c3;
  --warn-yellow-200: #feee95;
  --warn-yellow-300: #fde272;
  --warn-yellow-400: #fac515;
  --warn-yellow-500: #eaaa08;
  --warn-yellow-600: #ca8504;
  --warn-yellow-700: #a15c07;
  --warn-yellow-800: #854a0e;
  --warn-yellow-900: #713b12;
  --error-red-25: #fffbfa;
  --error-red-50: #fef3f2;
  --error-red-100: #fee4e2;
  --error-red-200: #fecdca;
  --error-red-300: #fda29b;
  --error-red-400: #f97066;
  --error-red-500: #f04438;
  --error-red-600: #d92d20;
  --error-red-700: #b42318;
  --error-red-800: #912018;
  --error-red-900: #7a271a;
  --success-green-25: #f6fef9;
  --success-green-50: #ecfdf3;
  --success-green-100: #d1fadf;
  --success-green-200: #a6f4c5;
  --success-green-300: #6ce9a6;
  --success-green-400: #32d583;
  --success-green-500: #12b76a;
  --success-green-600: #039855;
  --success-green-700: #027a48;
  --success-green-800: #05603a;
  --success-green-900: #054f31;
  --accent-yellow-100: #fffdcc;
  --accent-yellow-200: #fffc99;
  --accent-yellow-400: #fffa66;
  --accent-yellow-700: #fff500;
  --grey-50: #fafafa;
  --grey-100: #f5f5f5;
  --grey-200: #eeeeee;
  --grey-300: #e0e0e0;
  --grey-400: #bdbdbd;
  --grey-500: #9e9e9e;
  --grey-600: #757575;
  --grey-700: #616161;
  --grey-800: #424242;
  --grey-900: #212121;
  --red-50: #ffebee;
  --red-100: #ffcdd2;
  --red-200: #ef9a9a;
  --red-300: #e57373;
  --red-400: #ef5350;
  --red-500: #f44336;
  --red-600: #e53935;
  --red-700: #d32f2f;
  --red-800: #c62828;
  --red-900: #b71c1c;
  --hind-bar: rgba(58, 104, 127, 0.7);
  --hind-clock1: #4e7a9a;
  --hind-clock2: #6a95ac;
  --hind-clock3: #8ebbcf;
  --hind-clock4: #c4d6d9;
  --head-bar: rgba(102, 80, 126, 0.7);
  --head-clock1: #926d9f;
  --head-clock2: #af98c3;
  --head-clock3: #bebfd6;
  --head-clock4: #d6d6d6;
  --link-gray: #9e9ea6;
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #007bff;
  --secondary: #6c757d;
  --success: #28a745;
  --info: #17a2b8;
  --warning: #ffc107;
  --danger: #dc3545;
  --light: #f8f9fa;
  --dark: #343a40;
  --bg-body: #f4f4f4;
  --color-red-50: #fceeea;
  --color-red-100: #f8d4cb;
  --color-red-200: #f3b7a8;
  --color-red-300: #ee9a85;
  --color-red-400: #eb856b;
  --color-red-500: #e76f51;
  --color-red-600: #e4674a;
  --color-red-700: #e05c40;
  --color-red-800: #dd5237;
  --color-red-900: #d74027;
  --color-pink-50: #fbf1fc;
  --color-pink-100: #f2d0f3;
  --color-pink-200: #edc0ef;
  --color-pink-300: #e9aeeb;
  --color-pink-400: #e49fe7;
  --color-pink-500: #e295e5;
  --color-pink-600: #d587d8;
  --color-pink-700: #c979cc;
  --color-pink-800: #b46bb6;
  --color-pink-900: #a35ba5;
  --color-indigo-50: #e5e9ea;
  --color-indigo-100: #bec8cb;
  --color-indigo-200: #93a3a9;
  --color-indigo-300: #677e87;
  --color-indigo-400: #47626d;
  --color-indigo-500: #264653;
  --color-indigo-600: #223f4c;
  --color-indigo-700: #1c3742;
  --color-indigo-800: #172f39;
  --color-indigo-900: #0d2029;
  --color-blue-50: #ebfaff;
  --color-blue-100: #d8f5ff;
  --color-blue-200: #b1e9fb;
  --color-blue-300: #96e2fa;
  --color-blue-400: #85ddf9;
  --color-blue-500: #3cc8f4;
  --color-blue-600: #2fb1da;
  --color-blue-700: #2d8fc9;
  --color-blue-800: #0e78b6;
  --color-blue-900: #095480;
  --color-green-50: #e5f3f2;
  --color-green-100: #bfe2dd;
  --color-green-200: #95cec7;
  --color-green-300: #6abab1;
  --color-green-400: #4aaca0;
  --color-green-500: #2a9d8f;
  --color-green-600: #259587;
  --color-green-700: #1f8b7c;
  --color-green-800: #198172;
  --color-green-900: #0f6f60;
  --color-yellow-50: #fcf8ed;
  --color-yellow-100: #f8edd2;
  --color-yellow-200: #f4e2b5;
  --color-yellow-300: #f0d697;
  --color-yellow-400: #eccd80;
  --color-yellow-500: #e9c46a;
  --color-yellow-600: #e3b657;
  --color-yellow-700: #f9a825;
  --color-yellow-800: #dfaf4d;
  --color-yellow-900: #d9a23c;
  --color-grey-50: #fafcfd;
  --color-grey-100: #f5f9fc;
  --color-grey-150: #ebf3f9;
  --color-grey-200: #ebeff5;
  --color-grey-300: #dce3ed;
  --color-grey-400: #c6d3e4;
  --color-grey-500: #8191a5;
  --color-grey-600: #6a7c94;
  --color-grey-700: #5a697e;
  --color-grey-800: #353f4c;
  --color-grey-900: #212831;
}

:root {
  --border-radius-default: 8px;
  --box-shadow-default: 0 0 0 1px rgba(63, 63, 68, 0.05),
    0 1px 3px 0 rgba(63, 63, 68, 0.15);
  --box-shadow-shadow: 0 0 0 1px rgba(6, 44, 82, 0.1),
    0 2px 16px 0 rgba(33, 43, 54, 0.08);
  --box-shadow-subdued: 0 0 0 1px rgba(63, 63, 68, 0.07),
    0 1px 3px 0 rgba(63, 63, 68, 0.12);
  --spacing-base: 0.5rem;
  --spacing-tiny: var(--spacing-base);
  --spacing-medium: calc(var(--spacing-base) * 1.5);
  --spacing-small: calc(var(--spacing-base) * 2);
  --spacing-default: calc(var(--spacing-base) * 3);
  --spacing-large: calc(var(--spacing-base) * 4);
  --breakpoint-phone: 320px;
  --breakpoint-tablet: 768px;
  --breakpoint-desktop: 1024px;
  --breakpoint-wide: 1400px;
  --transition-timing-default: cubic-bezier(1, 0, 0.24, 0.77);
  --default-border: 1px solid #ccc;
}

.no-margin {
  margin: 0;
}

@font-face {
  font-family: "Baton Turbo";
  src: url("/assets/fonts/woff2/BatonTurbo-Regular.otf") format("otf");
  font-weight: normal;
}
@font-face {
  font-family: "Baton Turbo";
  src: url("/assets/fonts/woff2/BatonTurbo-Medium.otf") format("otf");
  font-weight: 500;
}
@font-face {
  font-family: "Baton Turbo";
  src: url("/assets/fonts/woff2/BatonTurbo-Book.otf") format("otf");
  font-weight: 600;
}
@font-face {
  font-family: "Ivar Headline";
  src: url("/assets/fonts/IvarHeadline-Regular.otf") format("otf");
}
:root {
  --font-headlines: "Baton Turbo";
  --font-body: "Baton Turbo";
  --font-display: "Ivar Headline", serif;
  --font-family-headlines: var(--font-headlines), sans-serif;
  --font-family-body: var(--font-body), sans-serif;
  --font-size-base: 16px;
  --font-size-caption: 0.875em;
  --font-size-tiny: 0.75em;
  --font-size-body-1: 1em;
  --font-size-body-2: 1.25em;
  --font-size-subheading: 1.125em;
  --font-size-headline: 1.5em;
  --font-size-display-1: 2.125em;
  --font-size-display-2: 2.8125em;
  --font-size-display-3: 3.5em;
  --font-size-display-4: 7em;
  --font-color-headlines: rgb(75, 75, 75);
  --font-color-body: var(--grey-900);
  --line-height-base: 1.5;
  --line-height-headlines: 1.25;
}

:root {
  font-size: var(--font-size-base);
}

body {
  font-family: var(--font-family-body);
  color: var(--font-color-body);
  line-height: var(--line-height-base);
  -webkit-font-smoothing: antialiased;
  text-rendering: geometricprecision;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: var(--spacing-default);
}
h1.display,
h2.display,
h3.display,
h4.display,
h5.display,
h6.display {
  font-family: var(--font-display);
}

.text-body-1 {
  font-size: var(--font-size-body-1);
  font-weight: 400;
}

.text-body-2 {
  font-size: var(--font-size-body-2);
  line-height: 130%;
  font-weight: normal;
}
@media (max-width: 1438px) {
  .text-body-2 {
    font-size: var(--font-size-body-1);
  }
}

.medium {
  font-weight: 500;
}

.bold {
  font-weight: 600;
}

.tiny {
  font-size: var(--font-size-tiny);
}

.text-caption {
  font-size: var(--font-size-caption);
}

.text-subheading {
  font-size: var(--font-size-subheading);
}
@media (max-width: 1438px) {
  .text-subheading {
    font-size: var(--font-size-body-1);
  }
}

.font-green {
  color: var(--color-primary);
}

.font-yellow {
  color: var(--color-warning);
}

.font-red {
  color: var(--color-error);
}

.text-title,
h2 {
  font-size: var(--font-size-display-1);
  font-weight: 600;
  line-height: var(--line-height-headlines);
}
.text-title.display,
h2.display {
  font-family: var(--font-display);
  font-size: var(--font-size-display-3);
  font-weight: 400;
}
@media (max-width: 1438px) {
  .text-title.display,
h2.display {
    font-size: var(--font-size-display-2);
  }
}

.text-headline {
  font-size: var(--font-size-headline);
  font-weight: 600;
  line-height: var(--line-height-headlines);
}

.large-font {
  font-size: var(--font-size-headline);
  font-weight: 400;
  line-height: 1.2;
}

h1.display {
  font-family: var(--font-display);
  font-size: 6em;
  font-weight: 300;
  line-height: 0.9;
  letter-spacing: 0;
}

.text-display-1 {
  font-size: var(--font-size-display-1);
  font-weight: 400;
  line-height: 120%;
}
@media (max-width: 1438px) {
  .text-display-1 {
    font-size: var(--font-size-headline);
  }
}
@media (max-width: 1023px) {
  .text-display-1 {
    font-size: var(--font-size-subheading);
  }
}

.text-display-2 {
  font-size: var(--font-size-display-2);
  font-weight: 600;
  line-height: var(--line-height-headlines);
}

.text-display-3 {
  font-size: var(--font-size-display-3);
  font-weight: 600;
  line-height: var(--line-height-headlines);
}

.text-display-4 {
  font-size: var(--font-size-display-4);
  font-weight: 600;
  line-height: var(--line-height-headlines);
}

.text-headline-2 {
  font-weight: 600;
  text-transform: uppercase;
  font-size: 0.9375rem;
  line-height: 1.188rem;
  letter-spacing: 0.54px;
}

.headline-divider {
  border-bottom: 1px solid var(--color-grey-300);
  padding-bottom: var(--spacing-base);
}

.text-subdued {
  color: var(--color-subdued);
}

.text-highlighted {
  color: var(--font-color-headlines);
}

.sidebar-headline {
  margin-bottom: var(--spacing-small);
}

.sidebar-subtitle {
  margin-top: var(--spacing-base);
}

.title-wrapper {
  margin-bottom: var(--spacing-large);
}
.title-wrapper h1 {
  margin-bottom: 4px;
}

a {
  text-decoration: none;
}

.align-center {
  text-align: center;
}

.card-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  grid-template-rows: auto;
  column-gap: var(--spacing-default);
  row-gap: var(--spacing-default);
}

.detailed-grid {
  display: grid;
  grid-template-areas: "history " "analysis";
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  gap: var(--spacing-default);
  margin: var(--spacing-default) 0;
}
.detailed-grid .history {
  grid-area: history;
}
.detailed-grid .comments {
  grid-area: comments;
}
.detailed-grid .analysis {
  grid-area: analysis;
}

.analysis-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: var(--spacing-large);
  grid-template-areas: "content sidebar";
}
.analysis-grid .content {
  grid-area: content;
}
.analysis-grid .content img {
  max-width: 100%;
}
.analysis-grid .sidebar {
  margin: var(--spacing-default) 0;
}
.analysis-grid .sidebar h3 {
  margin-bottom: var(--spacing-tiny);
}
.analysis-grid .sidebar hr {
  margin: var(--spacing-tiny) 0;
}
.analysis-grid .sidebar .graph-grid {
  grid-area: sidebar;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: var(--spacing-default);
}
.analysis-grid .sidebar .graph-grid img {
  max-width: 100%;
}
@media (max-width: 767px) {
  .analysis-grid {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto;
  }
  .analysis-grid .graph-grid {
    grid-template-columns: 1fr;
    grid-template-rows: auto;
  }
}

.single-analysis-grid {
  margin-top: var(--spacing-default);
  display: grid;
  gap: var(--spacing-default);
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr;
  grid-template-areas: "video video comment comment";
}
.single-analysis-grid .video {
  grid-area: video;
}
.single-analysis-grid .comment {
  grid-area: comment;
}
.single-analysis-grid .stridechart {
  grid-area: stridechart;
}
.single-analysis-grid .head1 {
  grid-area: head1;
}
.single-analysis-grid .head2 {
  grid-area: head2;
}
.single-analysis-grid .hind1 {
  grid-area: hind1;
}
.single-analysis-grid .hind2 {
  grid-area: hind2;
}

.twelve-grid {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  gap: var(--spacing-default);
}
.twelve-grid.large-padding {
  padding: calc(var(--spacing-base) * 19.5) 0;
}
@media (max-width: 767px) {
  .twelve-grid {
    grid-template-columns: repeat(4, 1fr);
  }
  .twelve-grid.large-padding {
    padding: calc(var(--spacing-base) * 8) 0;
  }
}

.box {
  background: #fff;
  border: 1px solid #ccc;
  padding: var(--spacing-default);
  overflow: hidden;
  max-width: 100%;
}
.box.no-padding {
  padding: 0;
}
.box.no-padding .box-section {
  padding: var(--spacing-default);
}
.box.overflow {
  overflow: visible;
}
.box.no-shadow {
  box-shadow: none;
}

.form-field {
  display: block;
  text-align: left;
}

.error-message {
  color: var(--color-red-900);
}

.form-field__label {
  font-size: 0.75rem;
  transform: translateY(-12px);
  display: block;
  font-weight: normal;
  left: 0;
  margin: 0;
  padding: 12px 12px 0;
  position: absolute;
  top: 0;
  transition: all 0.4s;
  width: 100%;
}

.form-field__control {
  background: var(--white);
  border-radius: 8px;
  border: 1px solid var(--green-100);
  overflow: hidden;
  position: relative;
  width: 100%;
}
.form-field__control.error {
  border-color: var(--color-red-900);
}
.form-field__control .mat-form-field {
  padding: var(--spacing-medium);
}
.form-field__control .mat-form-field .mat-form-field-label {
  font-size: 0.75rem;
  transform: translateY(-12px);
  display: block;
  font-weight: normal;
  left: 0;
  margin: 0;
  padding: 12px 12px 0;
  position: absolute;
  top: 0;
  transition: all 0.4s;
  width: 100%;
}

.form-field__input,
.form-field__textarea {
  appearance: none;
  background: transparent;
  border: 0;
  color: var(--grey-900);
  display: block;
  font-size: 1.2rem;
  margin-top: 24px;
  outline: 0;
  padding: 0 12px 10px 12px;
  width: 100%;
}
.form-field__input .icon.remove,
.form-field__textarea .icon.remove {
  fill: red;
}
.form-field__input:-ms-input-placeholder ~ .form-field__label,
.form-field__textarea:-ms-input-placeholder ~ .form-field__label {
  font-size: 1.2rem;
  transform: translateY(0);
}
.form-field__input:placeholder-shown ~ .form-field__label,
.form-field__textarea:placeholder-shown ~ .form-field__label {
  font-size: 1.2rem;
  transform: translateY(0);
}
.form-field__input:focus ~ .form-field__label,
.form-field__textarea:focus ~ .form-field__label {
  color: var(--color-green-500);
  font-size: 0.75rem;
  transform: translateY(-12px);
}
.form-field__input:focus ~ .form-field__control,
.form-field__textarea:focus ~ .form-field__control {
  border: 1px solid var(--green-200);
}
.form-field__input.small,
.form-field__textarea.small {
  margin-top: 18px;
  font-size: 1rem;
}

.form-field__textarea {
  height: 150px;
}

.nav-menu-item {
  margin-bottom: var(--spacing-small);
  display: block;
}

.red {
  fill: red;
}

.success-icon {
  width: 125px;
  stroke: var(--accent-mint-400);
}