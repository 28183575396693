:root {
  // PRIMARY
  --color-primary: var(--green-500);
  --color-primary-light: var(--green-50);
  --color-primary-dark: var(--green-700);
  --color-primary-hover: var(--secondary);

  // Primary brand

  --color-primary-brand: var(--color-indigo-400);
  --color-primary-brand-light: var(--color-indigo-300);
  --color-primary-brand-dark: var(--color-indigo-900);
  --color-primary-brand-hover: var(--color-indigo-600);

  // SECONDARY
  --color-secondary: var(--color-green-500);
  --color-secondary-light: var(--color-green-300);
  --color-secondary-dark: var(--color-green-900);
  --color-secondary-hover: var(--color-green-600);

  --color-success: var(--color-green-500);
  --color-success-light: var(--color-green-300);
  --color-success-dark: var(--color-green-900);
  --color-success-hover: var(--color-green-600);

  --color-error: var(--color-red-600);
  --color-error-light: var(--color-red-300);
  --color-error-dark: var(--color-red-900);
  --color-error-hover: var(--color-red-700);

  --color-warning: var(--color-yellow-500);
  --color-warning-light: var(--color-yellow-300);
  --color-warning-dark: var(--color-yellow-900);
  --color-warning-hover: var(--color-yellow-600);

  --color-subdued: #a7b1bf;

  // SLEIP COLORS:

  // Green
  --green-50: #ebf5f4;
  --green-100: #c2dad7;
  --green-200: #91bab5;
  --green-300: #59958e;
  --green-400: #297269;
  --green-500: #03594f;
  --green-600: #034a41;
  --green-700: #024139;
  --green-800: #02312c;
  --green-900: #012823;

  --accent-mint-100: #f6fefc;
  --accent-mint-50: #f0fdf9;
  --accent-mint-100: #c5f7ee;
  --accent-mint-200: #98f0e0;
  --accent-mint-300: #6cead3;
  --accent-mint-400: #2ed3b7;
  --accent-mint-500: #15b79e;
  --accent-mint-600: #0e9384;
  --accent-mint-700: #3fe4c5;

  --warn-yellow-25: #fefdf0;
  --warn-yellow-50: #fefbe8;
  --warn-yellow-100: #fef7c3;
  --warn-yellow-200: #feee95;
  --warn-yellow-300: #fde272;
  --warn-yellow-400: #fac515;
  --warn-yellow-500: #eaaa08;
  --warn-yellow-600: #ca8504;
  --warn-yellow-700: #a15c07;
  --warn-yellow-800: #854a0e;
  --warn-yellow-900: #713b12;

  --error-red-25: #fffbfa;
  --error-red-50: #fef3f2;
  --error-red-100: #fee4e2;
  --error-red-200: #fecdca;
  --error-red-300: #fda29b;
  --error-red-400: #f97066;
  --error-red-500: #f04438;
  --error-red-600: #d92d20;
  --error-red-700: #b42318;
  --error-red-800: #912018;
  --error-red-900: #7a271a;

  --success-green-25: #f6fef9;
  --success-green-50: #ecfdf3;
  --success-green-100: #d1fadf;
  --success-green-200: #a6f4c5;
  --success-green-300: #6ce9a6;
  --success-green-400: #32d583;
  --success-green-500: #12b76a;
  --success-green-600: #039855;
  --success-green-700: #027a48;
  --success-green-800: #05603a;
  --success-green-900: #054f31;

  --accent-yellow-100: #fffdcc;
  --accent-yellow-200: #fffc99;
  --accent-yellow-400: #fffa66;
  --accent-yellow-700: #fff500;

  --grey-50: #fafafa;
  --grey-100: #f5f5f5;
  --grey-200: #eeeeee;
  --grey-300: #e0e0e0;
  --grey-400: #bdbdbd;
  --grey-500: #9e9e9e;
  --grey-600: #757575;
  --grey-700: #616161;
  --grey-800: #424242;
  --grey-900: #212121;

  --red-50: #ffebee;
  --red-100: #ffcdd2;
  --red-200: #ef9a9a;
  --red-300: #e57373;
  --red-400: #ef5350;
  --red-500: #f44336;
  --red-600: #e53935;
  --red-700: #d32f2f;
  --red-800: #c62828;
  --red-900: #b71c1c;

  //*** Chart colors ***//

  --hind-bar: rgba(58, 104, 127, 0.7);
  --hind-clock1: #4e7a9a;
  --hind-clock2: #6a95ac;
  --hind-clock3: #8ebbcf;
  --hind-clock4: #c4d6d9;

  --head-bar: rgba(102, 80, 126, 0.7);
  --head-clock1: #926d9f;
  --head-clock2: #af98c3;
  --head-clock3: #bebfd6;
  --head-clock4: #d6d6d6;

  // colors Created before Sleip had a pallet
  --link-gray: #9e9ea6;
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #007bff;
  --secondary: #6c757d;
  --success: #28a745;
  --info: #17a2b8;
  --warning: #ffc107;
  --danger: #dc3545;
  --light: #f8f9fa;
  --dark: #343a40;
  --bg-body: #f4f4f4;

  //RED
  --color-red-50: #fceeea;
  --color-red-100: #f8d4cb;
  --color-red-200: #f3b7a8;
  --color-red-300: #ee9a85;
  --color-red-400: #eb856b;
  --color-red-500: #e76f51;
  --color-red-600: #e4674a;
  --color-red-700: #e05c40;
  --color-red-800: #dd5237;
  --color-red-900: #d74027;

  //PINK
  --color-pink-50: #fbf1fc;
  --color-pink-100: #f2d0f3;
  --color-pink-200: #edc0ef;
  --color-pink-300: #e9aeeb;
  --color-pink-400: #e49fe7;
  --color-pink-500: #e295e5;
  --color-pink-600: #d587d8;
  --color-pink-700: #c979cc;
  --color-pink-800: #b46bb6;
  --color-pink-900: #a35ba5;

  --color-indigo-50: #e5e9ea;
  --color-indigo-100: #bec8cb;
  --color-indigo-200: #93a3a9;
  --color-indigo-300: #677e87;
  --color-indigo-400: #47626d;
  --color-indigo-500: #264653;
  --color-indigo-600: #223f4c;
  --color-indigo-700: #1c3742;
  --color-indigo-800: #172f39;
  --color-indigo-900: #0d2029;

  //INDIGO
  --color-blue-50: #ebfaff;
  --color-blue-100: #d8f5ff;
  --color-blue-200: #b1e9fb;
  --color-blue-300: #96e2fa;
  --color-blue-400: #85ddf9;
  --color-blue-500: #3cc8f4;
  --color-blue-600: #2fb1da;
  --color-blue-700: #2d8fc9;
  --color-blue-800: #0e78b6;
  --color-blue-900: #095480;

  //GREEN
  --color-green-50: #e5f3f2;
  --color-green-100: #bfe2dd;
  --color-green-200: #95cec7;
  --color-green-300: #6abab1;
  --color-green-400: #4aaca0;
  --color-green-500: #2a9d8f;
  --color-green-600: #259587;
  --color-green-700: #1f8b7c;
  --color-green-800: #198172;
  --color-green-900: #0f6f60;

  --color-yellow-50: #fcf8ed;
  --color-yellow-100: #f8edd2;
  --color-yellow-200: #f4e2b5;
  --color-yellow-300: #f0d697;
  --color-yellow-400: #eccd80;
  --color-yellow-500: #e9c46a;
  --color-yellow-600: #e3b657;
  --color-yellow-700: #f9a825;
  --color-yellow-800: #dfaf4d;
  --color-yellow-900: #d9a23c;

  --color-grey-50: #fafcfd;
  --color-grey-100: #f5f9fc;
  --color-grey-150: #ebf3f9;
  --color-grey-200: #ebeff5;
  --color-grey-300: #dce3ed;
  --color-grey-400: #c6d3e4;
  --color-grey-500: #8191a5;
  --color-grey-600: #6a7c94;
  --color-grey-700: #5a697e;
  --color-grey-800: #353f4c;
  --color-grey-900: #212831;
}
