.mat-form-field {
  width: 100%;
}

/* Border */
.mat-form-field-appearance-outline {
  .mat-form-field-outline {
    color: var(--green-100);
  }
  .mat-form-field-outline-thick {
    color: var(--green-300);
  }

  &.mat-form-field-can-float-mat-form-field-should-float {
    .mat-form-field-outline-gap {
      border-top-color: var(--green-300);
    }
  }
  &.mat-focused {
    color: var(--green-300);
    .mat-form-field-outline-thick {
      color: var(--green-300);
    }
  }
}
/* Font color */
input.mat-input-element {
  // color: white;
}

.mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float
  .mat-form-field-outline-gap {
  border-top-color: var(--green-300);
}
