.machine-selector {
  &::after {
    position: absolute;
    right: 10px;
    top: 10px;
    content: " ";
    width: 24px;
    height: 24px;
  }
  &.idle::after {
    content: " ";
    -webkit-mask: url("/assets/images/check_circle-24px.svg") no-repeat 50% 50%;
    mask: url("/assets/images/check_circle-24px.svg") no-repeat 50% 50%;
    -webkit-mask-size: cover;
    mask-size: cover;
    background-color: green;
  }

  &.working:after {
    content: " ";
    -webkit-mask: url("/assets/images/info-24px.svg") no-repeat 50% 50%;
    mask: url("/assets/images/info-24px.svg") no-repeat 50% 50%;
    -webkit-mask-size: cover;
    mask-size: cover;
    background-color: var(--accent-yellow-700);
  }

  &.error::after {
    content: " ";
    -webkit-mask: url("/assets/images/info-24px.svg") no-repeat 50% 50%;
    mask: url("/assets/images/info-24px.svg") no-repeat 50% 50%;
    -webkit-mask-size: cover;
    mask-size: cover;
    background-color: var(--red-800);
  }
}
